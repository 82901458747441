import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '@/i18n';

import global from '@/store/global';
import saylau from '@/store/saylau';
import documents from '@/store/documents';
import archive from '@/store/archive';
import eventManagement from '@/store/event-management';
import orders from '@/store/orders';
import reportsgeneration from '@/store/reportsgeneration';
import chancellery from '@/store/chancellery';
import correspondence from '@/store/correspondence';
import edslight from '@/store/eds-light';

Vue.use(Vuex)

const store = new Vuex.Store({
    state:
    {
        /**Все доступные модули в клиентском приложении*/
        modules: [
            { name: "Главная", route: "/", icon: "far fa-home-alt", index: 0, access: { permissions: [] }, lgnamefirst: "integro", lgnamesecond: ""},
            { name: "Мероприятия", route: "/meetings", icon: "far fa-calendar-day", index: 1, access: { permissions: [ "CommonDocumentService" ] }, lgnamefirst: "integro", lgnamesecond: "meetings"},
            { name: "Избиратели", route: "/saylau", icon: "far fa-vote-yea", index: 2, access: { permissions: [ "Saylau" ] }, lgnamefirst: "integro", lgnamesecond: "saılaý"},
            { name: "Архив", route: "/archive", icon: "far fa-cabinet-filing", index: 3, access: { permissions: [ "Documents" ] }, lgnamefirst: "integro", lgnamesecond: "archive"},
            { name: "Поручения", route: "/orders", icon: "far fa-briefcase", index: 4, access: { permissions: [ "Documents", "CommonDocumentService" ] }, lgnamefirst: "integro", lgnamesecond: "orders"},
            { name: "Запросы", route: "/reportsgeneration", icon: "far fa-archive", index: 5, access: { permissions: [] }, lgnamefirst: "integro", lgnamesecond: "queries"},
            //{ name: "Канцелярия", route: "/chancellery", icon: "far fa-books", index: 6, access: { permissions: ["ChancelleryIncomingDocumentsList", "ChancelleryOutcomingDocumentsList", "ChancelleryInternalDocumentsList"], strategy: "HAS_ANY", requiredConfig: [ 8 ] }, lgnamefirst: "integro", lgnamesecond: "qūjattar"},
            { name: "Корреспонденция", route: "/correspondence", icon: "far fa-books", index: 7, access: { permissions: ["DocumentsCorrespondence" ], requiredConfig: [ 8 ] }, lgnamefirst: "integro", lgnamesecond: "qūjattar"},
            { name: "Корреспонденция", route: "/eds-light", icon: "far fa-books", index: 8, access: { permissions: ["DocumentsCorrespondence" ], requiredConfig: [ 64 ] }, lgnamefirst: "integro", lgnamesecond: "qūjattar"}
        ],
        currentModule: null,
        overlayVisible: false,
        overlayText: "",
        overlayProgress: null,
        /**
         * Объект, описывает текущее состояние пользователя.
         * @property {string} userActivityState.type - тип текущего состояния (idle / active)
         * @property {number} userActivityState.date - время записи состояния (unix timestamp)
        */
        userActivityState: null,
        drawerLeftMenuMinimized: false,
        clientConfig: null,
    },
    mutations:
    {
        SET_CURRENT_MODULE(state, payload) {
            state.currentModule = payload;
        },
        SET_OVERLAY_VISIBLE(state, payload) {
            state.overlayVisible = payload.visible;
        },
        SET_OVERLAY_TEXT(state, payload) {
            state.overlayText = payload.overlayText;
        },
        SET_OVERLAY_PROGRESS(state, payload) {
            state.progress = payload.progress;
        },
        SET_USER_ACTIVITY_STATE(state, payload) {
            state.userActivityState = payload;
        },
        SET_DRAWER_LEFT_MENU_MINIMIZED(state, payload) {
            state.drawerLeftMenuMinimized = payload;
        },
        SET_CLIENT_CONFIG(state, payload) {
            state.clientConfig = payload;
        }
    },
    getters:
    {
        isOverlayVisible: (s) => s.overlayVisible,
        getOverlayText: (s) => s.overlayText,
        getOverlayProgress: (s) => s.overlayProgress,
        getUserActivityState: (s) => s.userActivityState,
        isDrawerLeftMenuMinimized: (s) => s.drawerLeftMenuMinimized,
        getModules: (s) => s.modules,
        getCurrentModule: (s) => s.currentModule,
        //#region validation rules 
        getNumberNotZeroRule: () => {
            return [
                v => (typeof v == 'number' && v > 0) || i18n.t('Обязательное_поле')
            ]
        },
        getRequiredRule: () => {
            return [
                v => (!['string', 'number'].includes(typeof v) && !!v) || (typeof v == 'number' && v >= 0) || (typeof v == 'string' && !!v.trim()) || i18n.t('Обязательное_поле')
            ];
        },
        getMultipleRule: () => {
            return [
                v => v.length > 0 || i18n.t('Обязательное_поле')
            ];
        },
        getIntegerRule: () => {
            return [
                v => ( v >= 0 ) || i18n.t('Обязательное_поле'),
                v => ( v <= 2147483647 ) || 'Слишком большое значение для типа int'
            ];
        },
        getDefaultGuidRule: () => {
            return [
                v => (v !== "00000000-0000-0000-0000-000000000000" ) || i18n.t('Обязательное_поле')
            ];
        },
        getIntegerNotNullRule: () => {
            return [
                v => !!v || i18n.t('Обязательное_поле'),
                v => ( v > 0 ) || 'Значение_не_может_быть_меньше_или_равно_нулю',
                v => ( v <= 2147483647 ) || 'Слишком большое значение для типа int'
            ];
        },
        getPhoneRule: () => {
            return [
                v => /^\+?(-*\d){6,11}-*$/.test(v) || 'Некорректный_номер',
            ];
        },
        getIinRule: () => {
            return [
                v => (/^\d+$/.test(v) || v?.length === 0 || !v) || 'Только_числа',
                v => (String(v)?.length == 12 || String(v)?.length === 0 || !v) || '12 символов'
            ];
        },
        getEverydayPeriodRule: () => {
            return [
                v => !!v || i18n.t('Обязательное_поле'),
                v => ( v > 0 ) || 'Значение_не_может_быть_меньше_или_равно_нулю',
                v => ( v <= 365 ) || 'Слишком большое значение',
                v => (/^\d+$/.test(v)) || 'Только целые числа'
            ];
        },
        getMonthlyPeriodRule: () => {
            return [
                v => !!v || i18n.t('Обязательное_поле'),
                v => ( v > 0 ) || 'Значение_не_может_быть_меньше_или_равно_нулю',
                v => ( v <= 12 ) || 'Слишком большое значение',
                v => (/^\d+$/.test(v)) || 'Только целые числа'
            ];
        },
        getKvartalPeriodRule: () => {
            return [
                v => !!v || i18n.t('Обязательное_поле'),
                v => ( v > 0 ) || 'Значение_не_может_быть_меньше_или_равно_нулю',
                v => ( v <= 60 ) || 'Слишком большое значение',
                v => (/^\d+$/.test(v)) || 'Только целые числа'
            ];
        },
        getObjectIdRule: () => {
            return [
                v => !!v || i18n.t('Обязательное_поле'),
                v => !!v.id || i18n.t('Обязательное_поле')
            ];
        },
        getClientConfig: (state) => state.clientConfig
        //#endregion
    },
    actions: {
        /*async getClientConfig({state, commit}) {
            if (!state.clientConfig){
                var response = await httpAPI({
                    url: `api/references/getclientconfig`,
                    method: 'GET',
                });
                
                if (response)
                    commit({ type: 'SET_CLIENT_CONFIG', value: response.data.Payload });
            }
            return state.clientConfig
        },*/
        async setOverlayVisible({ commit }, { visible, text, localized = false }) 
        {     
            if (this.overlayVisible == visible)
                return;

            this.overlayVisibleValue = visible;

            if (visible) {
                await (new Promise(resolve => setTimeout(resolve, 200)));

                if (this.overlayVisibleValue != visible)
                    return;
            }
            else {
                commit({ type: 'SET_OVERLAY_PROGRESS', progress: null});  
            }

            commit({ type: 'SET_OVERLAY_VISIBLE', visible: this.overlayVisibleValue });
                
            if (text)
                commit({ type: 'SET_OVERLAY_TEXT', overlayText: localized ? text : i18n.t(text) });
            else
                commit({ type: 'SET_OVERLAY_TEXT', overlayText: i18n.t('Загрузка...') });
        },
        updateProgress({ commit }, { progress }) {
            commit({ type: 'SET_OVERLAY_PROGRESS', progress });
        }
    },
    modules: {
        global,
        saylau,
        documents,
        archive,
        eventManagement,
        orders,
        reportsgeneration,
        chancellery,
        correspondence,
        edslight
    }
});

export default store;

//делаем слепок чистого состояния без реактивностей
const initialState = JSON.stringify(store.state);

//метод для сброса состояние хранилища https://vuex.vuejs.org/api/#replacestate
export function resetStoreState() {
    store.replaceState(JSON.parse(initialState));
}